.footer-container {
    height: fit-content;
    padding-top: 80px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: row;
    color: whitesmoke;
    font-size: 16px;
    width: 100%;
}

.box {
    padding-left: 10%;
    width: 100%;
    align-items: center;
}

.footer-socials {
    display: flex;
    flex-direction: row;
}

.footer-socials svg {
    margin-right: 16px;
    cursor: pointer;
}

.footer-note {
    font-size: 42px;
    font-family: 'Zeyada';
    margin-bottom: 8%;
}

.footer-contact-info {
    font-size: 16px;
    line-height: 1.6em;
    margin-bottom: 8%;
}

.footer-contact-info a {
    color: whitesmoke;
    text-decoration: none;
}

.footer-download-cv-btn {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    height: 52px;
    width: fit-content;
    padding-left: 5%;
    padding-right: 5%;
    font-size: 16px;
    display: flex;
    background-color: whitesmoke;
    color: #0f174a;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 2px;
}

.footer-download-container {
    width: 80%;
    display: flex;
    flex-direction: row;
}


.footer-download-container a {
    width: fit-content;
    margin-left: auto;
    align-self: center;
}


@media screen and (max-width: 600px) {
    .footer-container {
        flex-direction: column;
        row-gap: 50px;
    }

    .box {
        width: auto;
    }
}