.main-page {
    color: whitesmoke;
}

.nav-bar {
    height: fit-content;
    width: inherit;
    font-size: 20px;
    align-items: center;
    margin-left: 10%;
}

.nav-bar ul {
    display: flex;
    flex-direction: row;
    width: inherit;
    margin: 0;
    padding: 2%;
    gap: 50px;
}

.nav-bar li {
    /* width: 100%; */
    list-style: none;
    font-family: "Sofia", sans-serif;
    cursor: pointer;
}

.nav-bar li a {
    color: whitesmoke;
    text-decoration: none;
}

.nav-bar li:hover {
    font-weight: 600;
}

.width-full {
    width: 100%;
}

.d-flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.page-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 760px;
}

.intro-image {
    /* position: relative; */
    /* display: inline-block; */
    /* flex-direction: column; */
    height: 760px;
    width: 73%;
    background-color: rgb(61 80 110);
    background-image: url(../../public/intro-image-1.jpg);
    background-size: 100% 100%;
    background-blend-mode: soft-light;
}

.intro-content {
    /* position: absolute;
    top: 80%;
    left: 30%;
    transform: translate(-30%, -80%); */
    margin-top: 400px;
    margin-left: 10%;
    font-size: 24px;
    /* font-size: 1.5vw; */
}

.intro-content .intro-head {
    font-family: 'Viga';
    text-transform: uppercase;
}

.intro-content .bold-name {
    font-family: 'Viga';
    font-size: 80px;
    /* font-size: 4.9vw; */
    font-weight: 900;
}

.intro-content .under-name {
    font-family: 'Ysabeau SC';
    font-style: italic;
    text-decoration: wavy;
    font-size: 21px;
    /* font-size: 1.28vw; */
}

.intro-text {
    display: flex;
    flex-direction: column;
    width: 27%;
    align-items: center;
    text-align: center;
    font-family: 'Viga';
    overflow-wrap: anywhere;
}

.intro-text .round-image {
    background-image: url("../../public/intro-round-img.jpg");
    height: 140px;
    width: 140px;
    /* align-self: center; */
    border-radius: 50%;
    background-size: 100% 100%;
    margin-bottom: 30px;
}

.intro-text .intro-text-name {
    font-size: 32px;
}

.intro-text .intro-text-creds {
    font-size: 16px;
    line-height: 1.6em;
    margin-bottom: 60px;
}

.intro-text .contact-info {
    font-size: 16px;
    line-height: 1.6em;
    margin-bottom: 25px;
}

.intro-text .contact-info a {
    color: whitesmoke;
    text-decoration: none;
}

.intro-text .socials {
    display: flex;
    flex-direction: row;
    margin-bottom: 42px;
}

.intro-text .socials svg {
    margin-right: 16px;
    cursor: pointer;
}

.intro-text .download-cv-btn {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    height: 52px;
    width: fit-content;
    padding-left: 7%;
    padding-right: 7%;
    margin: 5%;
    margin-bottom: 10px;
    font-size: 16px;
    display: flex;
    background-color: whitesmoke;
    color: #0f174a;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 2px;
}

@media screen and (max-width: 900px) and (min-width: 320px) {
    .intro-content .bold-name {
        font-size: 55px;
    }
}

@media screen and (max-width: 600px) {
    .page-content {
        flex-direction: column;
        height: 920px;
    }

    .nav-bar {
        font-size: 15px;
        margin-left: 5px;
    }

    .nav-bar ul {
        gap: 5px;
    }

    .intro-image {
        width: 100%;
        height: 400px;
    }

    .intro-content {
        margin-top: 100px;
        margin-left: 5%;
        font-size: 24px;
    }

    .intro-text {
        padding-top: 10px;
        width: 100%;
        height: 520px;
    }

    .skills-content-text {
        flex-direction: column;
    }

    .intro-text p {
        margin-bottom: 10px !important;
    }

    .intro-text div {
        margin-bottom: 0px !important;
    }

    .interests {
        flex-direction: column;
    }
}

@media screen and (max-width: 400px) {
    .intro-content {
        font-size: 19px;
    }

    .intro-content .bold-name {
        font-size: 45px;
    }

    .intro-content .under-name {
        font-size: 16px;
    }
}