.education-page {
    background-color: whitesmoke;
    height: fit-content;
    color: #0f174a;
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 21px;
    padding-top: 80px;
    padding-bottom: 80px;
    font-style: italic;
    font-family: "Tsukimi Rounded";
}

.education-content {
    width: 100%;
    padding-left: 10%;
}

.education-title {
    margin-bottom: 60px;
    width: inherit;
    font-size: 32px;
    font-family: "Sofia", serif;
}

.exp-company, .edu-school {
    cursor: pointer;
}

.exp-company:hover, .edu-school:hover{
    font-weight: 600;
}

.experience-content {
    width: 100%;
}

.exp, .edu {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
}

.experience-title {
    margin-bottom: 60px;
    width: inherit;
    font-size: 32px;
    font-family: "Sofia", serif;
}

.edu-year, .exp-year {
    font-weight: 600;
}